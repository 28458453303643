<template>
  <div>
    <div class="row plush" v-if="info.length > 0" v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="spanWidth" v-for="(item,index) in info" :key="index">
          <div class="plush-box" :class="spanWidth == 24?'marginaotu':''">
            <div class="plush-top food-categorie">
              <img :src="imgUrl + item.thumbnailList[0]" alt="" />
            </div>
            <div class="plush-bot button" @click="shopDeta(item)">{{$t('public.PlushSeries')}}</div>
          </div>
        </el-col>
        <!-- <el-col :span="6">
          <div class="plush-box">
            <div class="plush-top food-categorie">
              <img src="~@/assets/img/xiong.png" alt="" />
            </div>
            <div class="plush-bot button">Plush Series</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="plush-box">
            <div class="plush-top food-categorie">
              <img src="~@/assets/img/xiong.png" alt="" />
            </div>
            <div class="plush-bot button">Plush Series</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="plush-box">
            <div class="plush-top food-categorie">
              <img src="~@/assets/img/xiong.png" alt="" />
            </div>
            <div class="plush-bot button">Plush Series</div>
          </div>
        </el-col> -->
      </el-row>
    </div>
    <div class="nomr" v-else>
      <img src="@/assets/img/home/nomore.png" />
    </div>
    <div v-if="type=='products' || type=='frenkAll'">
      <el-pagination
          background
          :current-page="current"
          :page-sizes="[10, 20, 30]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: center;margin-top: 15px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>
  </div>
</template>

<script>
import { getAction } from "@/api/manage";
export default {
  name: "ProductList",
  components: {},
  props: ['type'],
  data() {
    return {
      loading: false,
      info: [],
      total: 0,
      current: 1,
      size: 8,
      imgUrl: process.env.VUE_APP_BASE_IMG,
      spanWidth: 6,

      types: '',

      name: ''
    }
  },
  //brother.vue
  //在同级别组件中声明监听事件
  created(){
    this.$bus.$on('brotherEvent', res=>{
      console.log(res) // res就是sister.vue里choseEvent方法传递的值data
      this.shopSearch(res)
    })
  },
  mounted() {
    this.name = this.$route.query.search
    this.shop()
  },
  methods: {
    shopSearch(name) {
      console.log(name);
      let form = {
          // isRecommended: 1, // 是否推荐，默认为否
          // isPopular: 1, // 是否热门，默认为否.
          name: name,
          pageSize: 8
        }
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tproductInfo/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          ...form
        }
      ).then((res) => {
        // console.log(this.type);
        let info = res.data.list;
        if (info.length > 0) {
          for (let i in info) {
            info[i].thumbnailList = info[i].thumbnail.split('|')
          }
          setTimeout(()=>{
            this.info = info
            this.total = res.data.count
          },50)
          
        }else{
          this.info = info
          this.total = res.data.count
        }
        this.loading = false
        document.getElementById('productList').scrollIntoView({ behavior: 'smooth' })
      });
    },
    shop() {
      this.loading = true
      let form = {}
      if (this.type == 'productDeta') {
        this.spanWidth = 24
      } else {
        this.spanWidth = 6
      }
      if (this.type == 'home') {
        form = {
          // isRecommended: 1, // 是否推荐，默认为否
          // isPopular: 1, // 是否热门，默认为否.
          pageSize: 8
        }
      }else if (this.type == 'products') {
        form = {
          // isRecommended: 1, // 是否推荐，默认为否
          // isPopular: 1, // 是否热门，默认为否
          pageSize: 8,
          // type: this.types,
          bigType: 1,
        }
        // console.log(form);
      }else if (this.type == 'frenkHot') {
        form = {
          // isRecommended: 0, // 是否推荐，默认为否
          isPopular: 1, // 是否热门，默认为否
          pageSize: 8,
          bigType: 2
        }
      }else if (this.type == 'frenkAll') {
        form = {
          // isRecommended: 1, // 是否推荐，默认为否
          // isPopular: 1, // 是否热门，默认为否
          pageSize: 4,
          bigType: 2
        }
      }else if (this.type == 'productDeta') {
        form = {
          // isRecommended: 1, // 是否推荐，默认为否
          // isPopular: 1, // 是否热门，默认为否
          pageSize: 2
        }
      }
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/tproductInfo/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          name: this.name,
          ...form
        }
      ).then((res) => {
        // console.log(this.type);
        let info = res.data.list;
        if (info.length > 0) {
          for (let i in info) {
            info[i].thumbnailList = info[i].thumbnail.split('|')
          }
          this.info = info
          this.total = res.data.count
          
        }else{
          this.info = info
          this.total = res.data.count
        }
        this.loading = false
      });
    },
    shopDeta(item) {
      // console.log(item);
      this.$router.push({
        path: "/ProductDeta",
        // query: { item: JSON.stringify(item) },
        query: { id: item.id },
      });
    },
    // 分页方法
    handleSizeChange(val) {
      this.size = val
      // this.loadData()
    },
    // 分页方法
    handleCurrentChange(val) {
      this.current = val
      // this.loadData()
    },
  },
};
</script>

<style scoped lang="scss">
.plush {
  margin-top: 70px;
}
.plush-box {
  text-align: center;
  .plush-top {
    width: 280px;
    height: 280px;
    background-color: #fff;
    border-radius: 89px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 140px;
      height: 178px;
      border-radius: 8px;
    }
  }
  .plush-bot {
    cursor: pointer;
    width: 290px;
    height: 60px;
    line-height: 60px;
    // background-color: #e6e5e1;
    // color: #9d856b;
    font-size: 22px;
    font-weight: bold;
    margin-top: 8px;
    border-radius: 10px;
  }
}
.marginaotu{
  .plush-top{
    margin: 0 auto;
  }
  .plush-bot{
    margin: 8px auto;
  }
}
.nomr{
  text-align: center;
  padding: 20px 0;
}
// .el-row:nth-child(2){
//   .marginaotu{
//     margin-top: 50px !important;
//   }
// }
</style>
<style scoped>
.plush >>> .el-col:nth-child(2) .marginaotu{
  margin-top: 50px;
}
</style>

