<template>
  <div>
      <el-carousel :interval="5000" arrow="always" height="634px">
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <a :href="item.url" v-if="item.url" target="_blank">
          <img :src="imgUrl + item.img" alt="" style="width: 100%;" />
        </a>
        <img v-else :src="imgUrl + item.img"  alt="" style="width: 100%;" />
        <!-- <span class="text">{{ item.text }}</span> -->
      </el-carousel-item>
    </el-carousel>
    </div>
</template>

<script>
import { getAction } from "@/api/manage";
export default {
  name: "Carousel",
  props: ['type'],
  components: {},
  data() {
    return {
      carouselList: [
        // {
        //   img: require("@/assets/img/xiaomi.png"),
        //   text: "See you at FREAKOUSE",
        // },
        // {
        //   img: require('@/assets/img/xiaomi.png'),
        //   text: 'See you at FREAKOUSE'
        // },
      ],
      imgUrl: process.env.VUE_APP_BASE_IMG
    };
  },
 
  mounted() {
    this.thomeRotation() //首页轮播图
  },
  methods: {
    thomeRotation() {
      let type = 2
      if (this.type == 'Products') {
        type = 2
      } else if (this.type == 'Home') {
        type = 1
      } else{
        type = 3
      }
      getAction(
        process.env.VUE_APP_BASE_URL + "/t/thomeRotation/listData",
        {
          '__ajax':"json",
          '__login':false,
          'language': localStorage.getItem('lang'),
          type: type
        }
      ).then((res) => {
        // console.log(res.data,111);
        this.carouselList = res.data.list;
        
      });
    }
  },
  
};
</script>

<style scoped lang="scss">
  // .text {
  //   position: absolute;
  //   width: 100%;
  //   left: 0;
  //   text-align: center;
  //   top: 31%;
  //   color: #fff;
  //   font-size: 80px;
  //   font-weight: bold;
  // }
</style>