<template>
  <div class="cont-box">
      <div class="container">
        <div class="title-box">
          <div class="title1"><img src="~@/assets/img/logo1.png" alt="" /></div>
          <div class="title2">
            <span class="titles">{{$t('public.Contactus')}}</span>
            <!-- <img src="~@/assets/img/title-list2.png" alt="" /> -->
          </div>
        </div>
        
        <el-form ref="form" :model="form" :rules="rules" class="demo-form-inline">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="" prop="name">
                    <el-input class="input-lst" v-model="form.name" :placeholder="$t('public.OurProducts')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="phone">
                    <el-input class="input-lst" v-model="form.phone" :placeholder="$t('public.Telephone')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="" prop="email">
                    <el-input class="input-lst" v-model="form.email" :placeholder="$t('public.Email')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="" prop="content">
                    <el-input type="textarea" :rows="8" class="input-lst input-textarea" v-model="form.content" :placeholder="$t('public.Yourinformation')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-form-item style="text-align: center;">
              <div class="btn-box">
                <div class="btn-box-text" @click="onSubmit('form')">{{$t('public.submitTo')}}
                  <img src="~@/assets/img/arr.png" alt="">
                </div>
              </div>
            </el-form-item>
        </el-form>
      </div>
    </div>
</template>

<script>
import { postAction } from "@/api/manage";
export default {
  name: "FormInput",
  components: {},
  data() {
    return {
      form: {},
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
      }
    };
  },
 
  mounted() {
    
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            postAction(process.env.VUE_APP_BASE_URL + "/t/tcontactUs/save", 
            this.form
            ).then((res) => {
              // console.log(res.data,1111);
              if (res.data.result == "true") {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.$refs[formName].resetFields();
              } else {
                this.$message.info(res.data.msg);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
  },
  
};
</script>

<style scoped lang="scss">
.title-box {
    padding: 10px 0;
    text-align: center;
    .title1{
      img{width: 100px;}
    }
    .title2{
      img{width: 500px;}
    }
  }
.cont-box {
    background: url("~@/assets/img/bg.png");
    background-size: 100% 100%;
    padding: 80px 0;
    .container{
      width: 1400px;
      margin: 0 auto;
    }
  }

  .input-lst{
    width: 630px;
  }
  .input-textarea{
    width: 96%;
  }
  .btn-box{
    text-align: center;
    margin-top: 40px;
    .btn-box-text{
      cursor: pointer;
      background: #BCAA94;
      width: 220px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      color: #fff;
      border-radius: 5px;
      margin: 20px auto;
      position: relative;
      box-shadow: 0px 1px 1px #BCAA94;
      transition: all 0.5s;
      img{
        position: absolute;
        right: 28px;
        top: 13px;
        width: 28px;
      }
    }
    .btn-box-text:hover{
      background: #aa967e;
    }
  }
</style>

<style scoped>
.input-lst >>> .el-input__inner, .input-lst >>> .el-textarea__inner{
  height: 70px;
  line-height: 70px;
  /* border: none; */
  border-radius: 13px;
  font-size: 20px;
  color: #D1C1AA;
  padding-left: 20px;
}
.input-lst >>> .el-textarea__inner{
  height: initial;
  line-height: initial;
}

.input-lst >>> .el-textarea__inner {
  font-family: Arial, Helvetica, sans-serif !important;
}
</style>
